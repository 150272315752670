<template>
  <base-layout>
    <v-row no-gutters>
      <v-col class="topMenu menu--container container">
        <v-slide-group
          show-arrows
          center-active
        >
          <v-slide-group-item
            v-for="(item, index) in menu"
            :key="index"
            v-slot="{ isSelected }"
          >
            <v-btn
              v-if="item?.children?.length"
              class="menuItem"
              :class="item.cssClass || ''"
              :color="isSelected ? 'primary' : undefined"
              v-bind="linkProps(item)"
            >
              {{ item.title }}
              <v-menu activator="parent" open-on-focus open-on-hover>
                <v-list>
                  <v-list-item
                    v-for="(subItem, subIndex) in item.children"
                    :key="subIndex"
                    v-bind="linkProps(subItem)"
                  >
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
            <v-btn
              v-else
              class="menuItem"
              :class="item.cssClass || ''"
              :color="isSelected ? 'primary' : undefined"
              v-bind="linkProps(item)"
            >
              {{ item.title }}
            </v-btn>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row no-gutters class="container--content">
      <v-col v-if="activeItem?.tabs?.length" cols="12">
        <v-tabs center-active class="tabs--container container tabs-square" density="compact">
          <v-tab
            v-for="(item, index) in activeItem.tabs"
            :key="index"
            v-bind="linkProps(item)"
            size="x-small"
            class=""
            :text="item.title"
            :loading="item.loading"
          />
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </base-layout>
</template>

<script setup lang="ts">
const route = useRoute();
const { fullMenu } = useMenu();

const menu = computed<MenuItem[]>(() => {
  const meniItem = fullMenu.find(item => item.to === route.matched[0]?.path);
  return meniItem?.children || [];
});

const linkProps = (item: MenuItem) => {
  if (item.external) {
    return { href: item.to };
  }
  return { to: item.to };
};

const activeItem = computed<MenuItem | undefined>(() => {
  const mainItem = menu.value.find(item => item.to === route.matched?.[1]?.path);
  if (!mainItem) {
    return undefined;
  }
  return mainItem.children?.find(item => item.to === route.matched?.[2]?.path) || mainItem;
});

watchEffect(() => { activeItem.value?.tabsLoader?.(); });
</script>
